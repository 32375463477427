import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import NextImage from "next/image";
import axios from "axios";
import { Api } from "../../networks/Api";
import { useTranslation } from "next-i18next";
import Script from "next/script";
import { getFingerprintData } from "../../redux/actions/fingerprintActions";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useFetchBrandList from "../../hook/useFetchBrandList";

const Seo = dynamic(() => import("./SEO"), { ssr: true });
const Head = dynamic(() => import("./Header"), { ssr: false });
const Pdpa = dynamic(() => import("./pdpa"), { ssr: false });
const Footer = dynamic(() => import("./Footer"), { ssr: false });

const app_env = process.env.NEXT_PUBLIC_APP_ENV;

dayjs.extend(utc);
dayjs.extend(timezone);

const App = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation("common");

  const brandData = useFetchBrandList(props?.token);
  const [openSearch, setOpenSearch] = useState(false);
  const [profileData, setProfileData] = useState({});

  const noHeader = props?.noHeader;
  const disableLine = props?.disableLine;
  const channel = profileData?.channel;

  const brand_filtered = brandData.filter((brand) =>
    brand?.name?.toLowerCase().includes(router?.query?.category?.toLowerCase())
  );
  const data_seo = props?.dataSeo;
  const dataReferrer = props?.dataReferrer || "";
  let queryParam = router?.query?.aid ? `aid=${router?.query?.aid}` : ``;
  queryParam = router?.query?.pid
    ? `${queryParam}&pid=${router?.query?.pid}`
    : queryParam;

  queryParam = router?.query?.pf
    ? `${queryParam}pf=${router?.query?.pf}`
    : queryParam;

  if (router?.query?.utm_source) {
    queryParam = queryParam
      ? `${queryParam}&utm_source=${router?.query?.utm_source}`
      : `?utm_source=${router?.query?.utm_source}`;
  }

  const postStatData = async (url, postData, token) => {
    try {
      await axios.post(url, postData, Api.Header(token || ""));
    } catch (error) {
      console.error(`Error posting stat data to ${url}:`, error);
    }
  };
  const postAccessLog = async (postData) => {
    try {
      const url = `${Api.createAccessLog}`;
      await axios.post(url, postData, Api.Header());
    } catch (error) {
      console.error("Error posting access log:", error);
    }
  };

  useEffect(() => {
    const fetchFingerprintData = () => {
      try {
        const storedFingerprint = Cookies.get("uuid") || uuidv4();
        Cookies.set("uuid", storedFingerprint, { expires: 7, path: "/" });
        dispatch(getFingerprintData(storedFingerprint));
      } catch (e) {
        console.error("Error in fetchFingerprintData:", e);
      }
    };

    fetchFingerprintData();
  }, [dispatch]);

  useEffect(() => {
    const postStatDataHandler = () => {
      if (router?.query) {
        const { aid, pid, pf } = router.query;
        const { asPath } = router;
        const baseUrl = asPath.split("?")[0];

        if (aid && pid) {
          const url = props?.token
            ? `${Api.ambassadorStatMember}`
            : `${Api.ambassadorStat}`;
          postStatData(
            url,
            {
              action: "view",
              url: baseUrl,
              aid,
              pid,
              uuid: Cookies.get("uuid"),
            },
            props?.token
          );
        }

        if (pf) {
          const url = props?.token
            ? `${Api.webpromotionStatMember}`
            : `${Api.webpromotionStat}`;
          postStatData(
            url,
            {
              action: "view",
              url: baseUrl,
              pid: pf,
              uuid: Cookies.get("uuid"),
            },
            props?.token
          );
        }
      }
    };

    postStatDataHandler();
  }, [router.query, props?.token]);

  useEffect(() => {
    const postAccessLogHandler = () => {
      try {
        let postData = {
          url: window?.location?.href,
          referer: dataReferrer,
          user_agent: navigator?.userAgent,
          uuid: Cookies.get("uuid"),
        };
        if (props?.token) {
          if (profileData?.channel == "line") {
            postData.channel = "line";
            postAccessLog(postData);
          }
        } else {
          postAccessLog(postData);
        }
      } catch (e) {
        console.error("Error in postAccessLogHandler:", e);
      }
    };

    postAccessLogHandler();
  }, [dataReferrer, props?.token, profileData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props?.token) {
          try {
            const profileResponse = await axios.get(
              Api.UserProfile,
              Api.Header(props?.token)
            );
            setProfileData(profileResponse.data.data);
          } catch (profileError) {
            console.error("Error fetching profile:", profileError);
            logout();
          }
        }
      } catch (e) {
        console.error("Error in profileResponse:", e);
      }
    };

    fetchData();
  }, [props?.token]);

  const onSearch = (value) => {
    router.push(`/all?search=${value}${queryParam ? `&${queryParam}` : ``}`);
  };

  return (
    <>
      <Seo data_seo={data_seo} brand_filtered={brand_filtered} />
      <Head props={props} setOpenSearch={setOpenSearch} />
      <div className="w-full">
        <div className="layout-main-content w-full">
          <div className={noHeader ? `noheader-content` : `main-content`}>
            <div className="flex justify-center">
              <div className="w-full max-w-screen-xl">
                {/* {props.children} */}
              </div>
            </div>
            {openSearch && (
              <div className="w-full fixed inset-0 bg-black bg-opacity-50 z-[99999]">
                <div className="bg-white shadow-lg w-full flex items-center">
                  <input
                    type="text"
                    placeholder="Search for brand, color, etc."
                    className="w-full p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-300"
                    onKeyDown={(e) =>
                      e.key === "Enter" && onSearch(e.target.value)
                    }
                  />
                  <button
                    className=" p-2 text-gray-600 hover:text-gray-800"
                    onClick={() => setOpenSearch(false)}
                  >
                    ✕
                  </button>
                </div>
              </div>
            )}
            {props.children}
          </div>
          {noHeader || channel == "line" ? (
            <></>
          ) : (
            <>
              <Footer t={t} />
              <Pdpa />
            </>
          )}
        </div>

        {disableLine || channel == "line" ? (
          <></>
        ) : (
          <Link href={`https://lin.ee/hfscV5M`}>
            <div target="_blank" rel="noopener noreferrer">
              <div className="line">
                <NextImage
                  src={"/static/images/icon-line.webp"}
                  className="line"
                  alt="line"
                  width={60}
                  height={60}
                  loading="lazy"
                  placeholder="blur"
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  unoptimized
                />
              </div>
            </div>
          </Link>
        )}
      </div>

      {app_env == "production" ? (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-WG86W24EG2"
            defer
          />
          <Script id="google-analytics" strategy="lazyOnload">
            {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());

         gtag('config', 'G-WG86W24EG2');
        `}
          </Script>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
